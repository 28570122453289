@import 'ui/theme/_variables.module.scss';
@import 'ui/theme/mixins.scss';

$color: #1955a6;
$margin: 3px;
$padding: 3px;
$minWidth: 15px;

.pagerContainer {
	display: flex;
	font-size: $font-large;
	align-items: center;
	color: #808080; //Slate gray
}

.page {
	margin: $margin;
	padding: $padding;
	border-radius: 3px;
	min-width: $minWidth;
	text-align: center;
}
.currentPage {
	background-color: $color;
	border: solid 1px $color;
	font-weight: bold;
	color: white;
	cursor: default;
}

.fetchingPage {
	background-color: lighten($color, 10%);
	border: solid 1px $color;
	font-weight: bold;
	color: white;
	cursor: default;
}

.otherPage {
	@include buttonHollow($color, white, black);

	background-color: white;
	border: solid 1px $color;
	cursor: pointer;
}

.loadMore {
	@include buttonHollow($color, white, black);

	background-color: white;

	margin: $margin;
	padding: $padding;
	// background-color: white;
	// border: solid 1px $color;
	border-radius: 3px;
	cursor: pointer;
}

.count {
	margin: $margin;
	padding: $padding;
	font-size: medium;
}

.nextIcon {
	color: $color;
}

.navigationMargin {
	// margin-left: 20px;
	// margin-right: 20px;
}
