@import 'ui/theme/_variables.module.scss';
@import 'ui/theme/mixins.scss';

$margin: 2px;

.filters {
	@include flexRow($margin);
	margin-bottom: 3px;

	align-items: center;
}

.filterSeparator {
	padding: 5px;
}

.filterItem {
	@include flexItem($margin);

	min-width: 2em;
	text-align: center;
	padding: 4px;
	font-size: $font-medium;

	border-radius: 3px;

	background-color: $backgroundColor;
	color: black;
	// font-weight: bold;

	cursor: pointer;

	@include transition(background-color 0.15s ease);
	@include transition(color 0.15s ease);

	&:hover {
		background-color: $backgroundColorHovered;
		// font-weight: bold;
		color: white;
	}

	&.selected {
		// font-weight: bold;
		cursor: default;
		background-color: $selectedColor;
		color: white;
	}

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
