.container {
	max-width: 1200px;
	min-height: 500px;
}

.alignOnRight {
	margin-right: -5px;
}

.indented {
	padding-left: 20px;
}

.verticalAlignCenter {
	display: flex;
	align-items: center;
}

.alignOnEnd {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.noMargin {
	margin: 0 !important;
}

.checkboxLabel {
	div {
		label {
			display: flex;

			div:nth-child(4) {
				padding-top: 5px;
			}
		}
	}
}
