.printersListScalesTable {
	width: 95%;
	display: flex;
	flex-direction: column;
}

.printerScaleListButtonContainer {
	margin: 20px 0 10px 0;
	display: flex;
	justify-content: flex-end;

	@media only screen and (max-width: 600px) {
		flex-direction: column;
	}
}

.printerScalesTable {
	font-size: 13px;
	border-radius: 3px;
	border: solid 1px lightgrey;

	thead {
		tr {
			th {
				padding: 0 10px;
				border: solid 1px lightgrey;
			}
			height: 2.5em;
		}
	}

	tbody {
		tr {
			vertical-align: middle;
			height: 2.5em;

			td {
				vertical-align: middle;
				padding: 0 10px;
				border: solid 1px lightgrey;
			}

			td:last-child > div {
				display: flex;
			}
		}
	}
}

.onlineIndicator {
	width: 14px;
	height: 14px;
}
