@import '../../../../theme/variables.module';

.addPrinterModal {
	width: 430px;
}

.addPrinterModalBody {
	padding: 0 65px;
}

.addPrinterModalFooter {
	padding: 0 15px;

	& .okButton {
		width: 100px;
		text-align: center;
	}
}
