.modalInputText {
	min-width: 200px;
}

.modalFormCheckbox {
	label {
		display: flex !important;
	}

	span:nth-child(1) {
		margin-right: 10px;
	}

	span:nth-child(3) {
		display: flex;
		align-items: center;
	}
}
