.pillSwitch {
	display: flex;
	justify-content: space-around;
	margin-top: 8px;
	padding: 2px 2px;
	border-radius: 20px;
	border: 1px solid gray;
}

.pill {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;

	width: 50%;
	padding: 8px 30px;
	font-size: 12px;
	border-radius: 20px;
}
