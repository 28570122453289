@import 'ui/theme/_variables.module.scss';

$sectionHeight: 175px;

.container {
	position: relative;
}

.displayOption {
	font-size: $font-huge;
	padding: 10px;
	opacity: 1;
	width: 200px;
}

.mapSection {
	border-radius: 5px;
	width: fit-content;
	box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4);
}

.profileSections {
	position: absolute;
	left: 225px;
	top: $sectionHeight;
}

.displayOptionContainer {
	position: absolute;
	top: $sectionHeight;
	left: 0px;
}

.displayShipmentDetailsOptionSpacing {
	padding: 0px;
	margin: 0 0 5px 5px;
}

.displayOptionContainerSpacing {
	padding: 0px;
	margin: 0 0 0 5px;
}

.summaryBar {
	position: absolute;
	align-items: flex-start;
	top: 0;
	left: 0;

	@media only screen and (max-width: 1500px) {
		flex-direction: column;
	}
}

.summaryBarSpacing {
	padding: 0px;
	margin: 5px 0 0 5px;
}

.subwaySection {
	position: absolute;
	top: 0;
	right: 0;
}

.subwaySectionSpacing {
	padding: 5px 5px 0px 5px;
	margin: 5px 5px 0 0;
}

.profileSectionsContainer {
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow-y: scroll;
}

.shipmentDetailsButton {
	border: 2px solid #041e42;
	border-radius: 5px;
}

.shipmentDetailsInteractionButtons {
	display: flex;
	margin: 5px 0 0 10px;
	padding: 5px;

	@media only screen and (max-width: 1500px) {
		margin: 5px 0 0 5px;
	}
}

.shipmentDetailsButtonInactive {
	background-color: #041e42;
	color: #fff;

	&:hover {
		background-color: #041e42d9;
	}
}

.shipmentDetailsButtonActive {
	background-color: #02a7f0;
	color: #041e42;

	&:hover {
		background-color: #02a7f0d9;
	}
}

.navButtonsActive {
	background-color: #79c300;
	color: #000;

	&:hover {
		background-color: #79c300d9;
	}
}

.navButtons {
	div:first-child {
		border-radius: 5px 5px 0 0;
	}

	div:last-child {
		border-radius: 0 0 5px 5px;
	}
}

.mainProfileSectionSpacing {
	min-width: 1200px;
	padding: 5px;
}

.noBoxShadow {
	box-shadow: none;
}

.logOffer {
	position: fixed;
	right: -40px;
	top: 200px;
	transform: rotate(-90deg);
	z-index: 10000;
}
