.link {
	padding: 0;
}

.linkButtonContainer {
	display: flex;

	& .linkButton {
		background-color: transparent;
		border: none;
		cursor: pointer;
		text-decoration: underline;
		display: inline;
		margin: 0;
		padding: 0;
	}

	& .linkSpinner {
		margin-left: 3px;
	}
}
