$primary-color: #041e42;

.header-wrapper {
	.navbar {
		background-color: transparent !important;

		.logo {
			padding-left: 10px;
		}

		.username-wrapper {
			color: #041e42;
			font-size: 0.8em;
			font-weight: bold;
			line-height: 1.5;
			display: flex;
			align-items: center;
		}

		.username-pipe {
			font-size: 24px;
			padding-left: 14px;
		}

		#logout-button {
			color: $primary-color;
			text-decoration: none;
			font-weight: bold;
			font-size: 0.8em;
			border: none;
			outline: none;
			transition: 0s;
			background-color: transparent;

			span {
				color: $primary-color;
			}

			&:hover,
			&:focus,
			&:focus:hover {
				outline: none;
				color: $primary-color;
				border: none;
				box-shadow: none;
				background-color: #ccc;

				svg {
					fill: $primary-color;
				}
			}
		}
	}
}
