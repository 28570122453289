@import 'ui/theme/_variables.module.scss';

$dockLabelHeight: 124px;
$appointmentInfoHeight: $dockLabelHeight;

.dockName {
	font-size: $font-large;
	height: $dockLabelHeight;
	padding: 5px;
	width: 100px;
	word-wrap: break-word;
	text-align: center;
}

.appointmentStatusItem {
	border-radius: 2px;
	padding: 5px;
	flex-grow: 1;
	flex-basis: 0;
	margin-top: 5px;
	margin-bottom: 5px;
}

.mainItem {
	font-weight: bold;
	font-size: $font-large;
	margin-top: 5px;
}

.subItem {
	margin-left: 10px;
	font-size: $font-medium;
}

.appointmentDetailsBox {
	padding: 5px;
	width: 375px;
	height: $appointmentInfoHeight;
	border-radius: 3px;
}

.appointmentStatusBadge {
	padding: 5px 20px;
	border-radius: 15px;
	font-weight: bold;
	margin-bottom: 5px;
	text-align: center;
}

.flexContainer {
	display: flex;
	flex-wrap: wrap;
	max-width: 1200px;
}

.flexItem {
	width: fit-content;
	margin-right: 30px;
}

.time {
	font-weight: bold;
	font-size: $font-larger;
}

.timePiece {
	margin: 0px;
	width: 160px;
}

.alignBottom {
	position: absolute;
	bottom: 0;
}

.badgesAndProvider {
	position: relative;
	height: $dockLabelHeight;
}

.providerName {
	font-weight: bold;
	font-size: $font-huge;
}
