.newQuoteParcel {
	margin-left: 10px;
	& .newQuoteParcelRow {
		max-width: 1430px;
		& .newQuoteParcelCol {
			flex: 1;
			&.newQuoteParcelColNoPaddingRight {
				padding-right: 0;
			}
			&.locationDropDownNoMargin {
				flex: initial;
				margin: 0;
			}
		}
	}
}

.newQuoteParcelLoadOrderInput {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

#overridePrinterLocationDropDown {
	margin: 100px;
}

.icon {
	padding: 30px 0 10px;
	font-size: 64px;
}
