@import 'ui/theme/_variables.module.scss';

.row {
	display: flex;
	flex-direction: 'row';
}

.offerDetailsSection {
	margin: 10px;
}

.indent {
	margin-left: 35px;
}

.bold {
	font-weight: 600;
}

.boldest {
	font-weight: bold;
}

.sectionSeparation {
	margin-top: 20px;
}

// .offerInfoContainer {
// 	max-width: 400px;
// }

.detailsItem {
	height: 350px;
	display: flex;
	flex-direction: column;
}

.offerDetailsProviderInformation {
	min-width: 250px;
	max-width: 350px;
}

.headerText {
	color: black;
	display: inline-block;
}

.offerDetailsItem {
	min-height: 550px;
}

.makeOfferPage {
	max-width: 1400px;
}

.modalShipment {
	min-width: 400px;
	padding: 10px;
}

.modalShipmentButton {
	padding-top: 20px;
}

.totalTable {
	tbody {
		tr {
			td {
				&:first-child {
					padding-right: 1em;
				}
				&:last-child {
					text-align: right;
				}
			}
		}
	}
}

.large {
	font-size: $font-larger;
}
