.fileDropZone {
	border: dashed 2px black;
	text-align: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;

	p {
		margin: 1rem 0 !important;
		padding: 0 3px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.fileDropZoneContainer {
	width: 100%;
	height: 300px;
	margin-right: 20px;
}

.fileDropZoneInput {
	cursor: pointer;
}
