.flexItem {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 1;
}
.allowShrink {
	flex-shrink: 1;
}
.fitContent {
	width: fit-content;
}
.alignRight {
	align-self: flex-end;
}

.alignCenter {
	display: flex;
	justify-content: center;
}
