.cardContainer {
	.cardTitle {
		span {
			font-weight: bold;
		}
	}

	.cardRadioBox {
		margin-bottom: 32px;

		> div > label {
			margin-bottom: 18px;
		}
	}

	.cardCheckBox {
		margin: 24px 0 36px;

		input[type='checkbox'] {
			margin-top: 0;
			padding: 0;
		}
	}

	.warningTreshhold {
		display: flex;

		div:nth-child(2) {
			padding: 0;
			text-align: left;

			display: flex;
			align-items: center;

			label {
				margin: 0;
			}
		}
	}

	.saveButton {
		text-align: center;
		margin: 16px 0 0;
		padding: 6px 36px !important;
	}

	label {
		margin-bottom: 8px;
	}

	.input:disabled {
		background-color: grey;
	}

	.flexEnd {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
	}
}
