@import 'ui/theme/_variables.module.scss';

$blue: #041e42;
$lightBlue: #59cbe8;
$darkBlue: #21366e;
$purple: #6068b2;
$green: #79c300;

$greenAlt: #008000;

$color: $darkBlue;
$selectedColor: $green;

.icon {
	font-size: 16px;
	color: $color;
	&.selected {
		color: $selectedColor;
	}
	&.hovered {
		color: $selectedColor;
	}
}
.radios {
	display: flex;
	&.vertical {
		flex-flow: column;
	}
	&.horizontal {
		div {
			min-width: 125px;
		}
	}
}
.radio {
	margin: 2px;

	label {
		font-size: 14px;

		input {
			opacity: 0;
			height: 0;
			width: 0;
		}
	}
}
