.blue {
	background-color: blueviolet;
}

$seriesColors: purple, yellow, red, blue;
@each $seriesColor in $seriesColors {
	$idx: index($seriesColors, $seriesColor);
	.seriesColor#{$idx} {
		background-color: $seriesColor;
	}
}
