@import 'ui/theme/_variables.module.scss';

.newQuoteAccessorial {
	font-size: $font-large;
	min-height: 326px;
}

.indentContainer {
	margin: 10px 0 10px 25px;
}

.flexContainer {
	display: flex;
}

.flexContainer > div:first-child {
	padding-right: 12px;
}
