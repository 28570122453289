@import '../../../theme/variables.module';

.large {
	input,
	div {
		font-size: $font-sm !important;
	}
}

.disabled {
	input,
	div {
		border: 0 !important;
	}

	input:last-child {
		border: 0 !important;
	}
}
