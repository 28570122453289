.flexContainer {
	display: flex;
	justify-content: space-between;
}

.titleButtonContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buttonContainer {
	display: flex;
	justify-content: space-between;
	width: 300px;

	button {
		white-space: nowrap;
		color: #fff;
		font-size: 14px;
	}
}

.confirmDeleteModal {
	display: flex;
	flex-direction: column;
}

.smlInputBox {
	width: 40px;
	padding-right: 15px;
}

.medInputBox {
	max-width: 190px;
	padding-right: 15px;
}

.iconButtonContainer > div {
	padding: 15px 10px 0 10px;
}

.packageIndex {
	display: flex;
	align-items: center;
	font-size: 13px;
	padding: 0 10px;
	font-weight: bold;
	color: #21366e;
}

.goodsSelection {
	display: flex;
	gap: 15px;
	margin-bottom: 10px;
	line-height: 20px;
}

.splitButton {
	min-width: fit-content;
}
.hideSplitButton {
	display: none;
}
.splitPlaceHolder {
	margin-right: 10px;
}
