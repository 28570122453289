.loadedBar {
	min-height: 2px;
	height: 2px;
	background-color: #1955a6;
}

.loadingBar {
	border-radius: 2px;
	height: 2px;
	background-color: #ccc;
}
