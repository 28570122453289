.titledBorderBox {
	border-radius: 3px;
	position: relative;
	padding: 15px;
	border: 1px solid #ddd;

	.titledBorderBoxTitle {
		background-color: white;
		position: absolute;
		top: -10px;
		display: block;
		padding: 5px 15px;
		left: 10px;
	}
}
