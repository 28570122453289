.highlightBox {
	border-radius: 3px;
	position: relative;
	padding: 5px;
}

.tooltipText {
	position: absolute;
	z-index: 1;
	padding: 2px;
}

.topLeft {
	top: 0px;
	left: 0px;
	border-right-width: 1px;
	border-right-style: solid;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-right-radius: 3px;
}

.topRight {
	top: 0px;
	right: 0px;
	border-left-width: 1px;
	border-left-style: solid;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-left-radius: 3px;
}

.bottomLeft {
	bottom: 0px;
	left: 0px;
	border-right-width: 1px;
	border-right-style: solid;
	border-top-width: 1px;
	border-top-style: solid;
	border-top-right-radius: 3px;
}

.bottomRight {
	bottom: 0px;
	right: 0px;
	border-left-width: 1px;
	border-left-style: solid;
	border-top-width: 1px;
	border-top-style: solid;
	border-top-left-radius: 3px;
}
