@import 'ui/theme/_variables.module.scss';

.sideMargin {
	margin: 0px 5px;
}

.consolidatedShipmentCard {
	border-radius: 3px;
	padding: 3px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.consolidatedPayloadCard {
	border-radius: 3px;
	font-size: $font-medium;
	margin: 3px 0;
}

.payloadPopup {
	width: 215px;
	font-size: $font-small;
}

.payloadPopupBreaker {
	margin-left: 95%;
}

.mediumText {
	font-size: $font-medium;
}

.thresholdInput {
	width: 75px;
}

.deleteRuleIcon {
	font-size: 30px;
	text-align: center;
}

.deleteRuleSpinner {
	font-size: $font-medium;
	text-align: center;
	padding: 8px 0px;
}

.searchInput {
	height: 29px;
	& input {
		margin-top: 4px;
		font-size: $font-large;
		font-family: inherit;
	}
}

.deleteIcon {
	font-size: 20px;
}

.mediumFont {
	font-size: $font-medium;
}

.fullScreen {
	margin: 10px;
}
