.packagingCatalogTableContainer {
	margin: 0px 20px;
	border: 1px solid #000;
}

.packagingCatalogRowContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;

	input {
		margin: 0 10px;
		width: 200px;
		text-align: center;
	}
}

.packagingCatalogTableLabel {
	display: flex;
	align-items: center;
	background-color: #ddd;

	div {
		width: 200px;
		padding: 12px 10px;
		font-size: 14px;
		text-align: center;
	}
}

.addCloseButton {
	margin: 10px 20px;
}

.iconContainer {
	width: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.iconButtonContainer {
	padding: 0 10px;
}
