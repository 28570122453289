@import '../../theme/variables.module';

.customerInvoiceContainer {
	display: flex;
	flex-direction: column;
}

.noWrap {
	flex-wrap: wrap !important;
}

.noLeftPadding {
	padding-left: 0 !important;
}

.subwayContainer {
	display: flex;
	flex-flow: row-reverse;
	margin-top: 30px;
}

.pageTitle {
	margin: 10px 0 20px 0;
	display: flex;
	align-items: center;

	h1 {
		white-space: nowrap;
		margin-right: 16px;
	}

	span {
		font-family: 'Montserrat', Arial, Helvetica, sans-serif;
		font-size: $font-xl;
		font-weight: bold;
		height: $font-xl;
		margin-right: 16px;
		width: 180px;
	}

	label,
	input {
		font-family: 'Montserrat', Arial, Helvetica, sans-serif;
		font-size: $font-xl;
		font-weight: bold;
		height: $font-xl;
		margin-right: 16px;
		width: 180px;
	}

	input {
		margin-top: -1px;
		margin-left: -3px;
		margin-right: 21px;
	}

	> div {
		margin: 0;
		padding: 0;
		display: flex;
	}

	> div:nth-child(2) {
		width: 100px;
		align-items: center;
		flex: 1;
		margin-left: 155px;

		.editSaveIcon {
			font-size: $icon-lg;
			margin-right: 12px;
		}

		.editCancelIcon {
			font-size: $icon-xl;
		}
	}
}

.invoiceStatusButton {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.brokerInvoiceButtons > button {
		background-color: transparent;
		color: #2ea9f0;
		text-decoration: underline;

		&:hover {
			background-color: transparent;
		}
	}
}

.voidedInvoiceText {
	color: red;
	font-weight: bold;
}
