$breakpoint: 1250px;

.commoditiesTable {
	width: 100%;
	border-spacing: 0;
}

.editCommoditiesContainer {
	@media (min-width: $breakpoint) {
		width: 1148px;
	}
}

.commoditiesTable thead {
	border-spacing: 0;
	background-color: #c9c9c9;
	color: #000;
	// text-transform: uppercase;
}

.labelText {
	color: #555;
	display: inline-block;
}

.commoditiesLabelColor {
	color: #555;
}
.commoditiesTable th,
.commoditiesTable td {
	padding: 8px 4px;
	text-align: left;
}

.commoditiesTable tbody tr:nth-child(odd) {
	background-color: #fafafa;
}

.commoditiesTable tbody tr:hover {
	background-color: #eee;
}

.commoditiesTable thead th.normalCaps {
	text-transform: none;
}

.commoditiesTotals {
	display: flex;
	align-items: center;
	> div {
		margin-right: 30px;
	}

	.labelText {
		margin-right: 10px;
	}
}

.smaller {
	font-size: smaller;
}

.commoditiesTotalLinearFeet {
	display: flex;
	white-space: nowrap;
	line-height: 22px;
}
