@import 'ui/theme/_variables.module.scss';

$color: #ccc;

.chip {
	border: none;
	border-radius: 10px;
	min-width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: $font-medium;
	margin: 1px;
	height: 20px;
}
