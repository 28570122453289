@import 'ui/theme/_variables.module.scss';

.devBorder {
	border: 1px blue dashed;
}

.featureToggleLabel {
	margin-top: -8px;
	font-size: $font-medium;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;
	color: blue;
	width: max-content;
	background-color: #f1f3fa;
}

.notAuthorized {
	font-size: $font-huge;
	color: #7bbbd6;
}

.loading {
	font-size: $font-large;
	color: #7bbbd6;
}
