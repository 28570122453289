.modalWrapper {
	div {
		div {
			div {
				&.modalCol {
					padding-left: 25px;
					padding-right: 25px;
				}
			}
		}
	}
}
