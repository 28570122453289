@import 'ui/theme/_variables.module.scss';

.container {
	width: 1105px;
}

.rfpProjectCard {
	width: 100%;
	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: black;
	padding: 3px;

	&:last-child {
		border-radius: 0px 0px 3px 3px;
		border-bottom-width: 1px;
	}

	&:first-child {
		border-radius: 3px 3px 0px 0px;
	}
}

.rfpProjectLabel {
	padding: 0px;
	font-size: $font-large;
}

.label {
	font-size: $font-large;
	margin-right: 7px;
}

.topAlign {
	margin-top: 0px;
}

.subItem {
	margin-left: 10px;
	margin-bottom: 3px;
	font-size: $font-medium;
}

.removeIcon {
	font-size: 30px;
	margin: -8px -2px 0px 0px;
	vertical-align: top;
}

.editIcon {
	padding-top: 1px;
	font-size: 11px;
}

.projectContainer {
	margin-right: 8px;
	margin-top: 3px;
}

.icon {
	padding-top: 1px;
	font-size: 11px;
}
