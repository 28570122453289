$gray: #ccc;
$blue: #1955a6;
$lightBlue: lightBlue;
$green: #80d721;
$orange: orange;
$red: #b20023;
$textColor: black;
$white: white;
$black: black;
$purple: purple;

$disabledBackground: lighten(#1955a6, 50%);
$disabledColor: black;

$seriesColors: $blue, $green, darken($blue, 10%), darken($green, 10%);

@each $seriesColor in $seriesColors {
	$idx: index($seriesColors, $seriesColor);
	.seriesColor#{$idx} {
		background-color: $seriesColor;
	}
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin button($color, $textColor) {
	cursor: pointer;

	@include transition(color 0.2s ease);
	@include transition(background-color 0.2s ease);
	background-color: $color;

	color: $textColor;

	&:disabled {
		background-color: $disabledBackground;
		color: $disabledColor;
	}

	&:hover {
		background-color: darken($color, 10%);
		color: darken($textColor, 20%);

		&:disabled {
			background-color: $disabledBackground;
			color: $disabledColor;
		}
	}
	&:active {
		background-color: darken($color, 20%);
		color: darken($textColor, 30%);

		&:disabled {
			background-color: $disabledBackground;
			color: $disabledColor;
		}
	}
}

$colors: (
	gray: (
		$gray,
		$black,
	),
	red: (
		$red,
		$white,
	),
	green: (
		$green,
		$black,
	),
	blue: (
		$blue,
		$white,
	),
	orange: (
		$orange,
		$black,
	),
	purple: (
		$purple,
		$black,
	),
	lightBlue: (
		$lightBlue,
		$black,
	),
);
@each $name, $color in $colors {
	.#{$name} {
		background-color: nth($color, 1);
	}
	.#{$name}Text {
		color: nth($color, 1);
	}
	.#{$name}Button {
		@include button(nth($color, 1), nth($color, 2));
	}
	.#{$name}Border {
		border: solid 1px nth($color, 1);
	}
	.#{$name}BorderBottom {
		border-bottom: solid 1px nth($color, 1);
	}
	.#{$name}Highlight {
		&:hover {
			background-color: mix($white, nth($color, 1), 80%);
		}
	}
	.#{$name}HighlightTooltip {
		background-color: mix($white, nth($color, 1), 40%);
		border-color: mix($white, nth($color, 1), 40%);
		color: nth($color, 2);
	}
}
