.block {
	width: 110px;
}

.sellSection {
	padding: 5px;
	border-radius: 10px;
	margin-bottom: 20px;
	display: inline-block;
}

.sellLabel {
	// border-bottom: 1px solid black;
	font-size: 16px;
}

.sellDetails {
	padding: 5px;
	border-radius: 5px;
}

.buySection {
	padding: 5px;
	border-radius: 10px;
	margin-bottom: 10px;
}

.buyLabel {
	// width: 10%;
	// border-bottom: 1px solid black;
	// border-bottom: 1px solid black;
	font-size: 16px;
}

.buyDetails {
	padding: 5px;
	border-radius: 5px;
	background-color: white;
}

.shipmentProfileLogOfferButton {
	margin-top: 5px;
	margin-right: -5px;
}

.shipmentProfileNewRatesButton {
	margin-top: 10px;
}
