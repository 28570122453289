@import 'ui/theme/_variables.module.scss';

.shipmentSummaryCard {
	border-radius: 5px;
	padding: 6px;

	& .badgeSection {
		margin-left: 15px;
		font-size: $font-medium;
	}
}

.shipmentIdMargin {
	margin: 0 7px 0 5px;
}

.shipmentBigBadge {
	border-radius: 5px;
	margin: 5px;
	padding: 7px 10px;
}

.preparingBgColor {
	background-color: #f6cb3c;
	color: #fff;
}

.quotedBgColor {
	background-color: #041e42;
	color: #fff;
}

.activeBgColor {
	background-color: #f6cb3c;
	color: #fff;
}

.activeInvitesNotSentBgColor {
	background-color: #f6cb3c;
	color: #fff;
}

.activeInvitesSentBgColor {
	background-color: #f6cb3c;
	color: #fff;
}

.activeWithOffersBgColor {
	background-color: #041e42;
	color: #fff;
}

.awardedBgColor {
	background-color: #79c300;
	color: #fff;
}

.bookedBgColor {
	background-color: #79c300;
	color: #fff;
}

.voidBgColor {
	background-color: #df2726;
	color: #fff;
}

.noLongerInErpBgColor {
	background-color: #df2726;
	color: #fff;
}
