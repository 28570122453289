@import 'ui/theme/_variables.module.scss';

.shipmentSummaryCard {
	border-radius: 5px;
	padding: 6px;

	& .badgeSection {
		margin-left: 15px;
		font-size: $font-medium;
	}
}

.customerName {
	margin: 8px 0 8px 20px;
}

.shipmentIdMargin {
	margin: 0 7px 0 5px;
}

.shipmentBigBadge {
	border-radius: 5px;
	margin: 5px;
	padding: 7px 10px;
}

.needsQuoteBgColor {
	background-color: #f6cb3c;
	color: #fff;
}

.needsTransportBgColor {
	background-color: #f6cb3c;
	color: #fff;
}

.quotedBgColor {
	background-color: #041e42;
	color: #fff;
}

.awardedBgColor {
	background-color: #79c300;
	color: #fff;
}

.bookedBgColor {
	background-color: #79c300;
	color: #fff;
}

.deliveredBgColor {
	background-color: #79c300;
	color: #fff;
}

.inTransitBgColor {
	background-color: #59cbe9;
	color: #fff;
}

.lostBidBgColor {
	background-color: #df2726;
	color: #fff;
}

.canceledBgColor {
	background-color: #df2726;
	color: #fff;
}
