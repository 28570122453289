.loadOrder {
	& .loadOrderInputAndButton {
		align-items: center;

		& .loadOrderColNoPaddingRight {
			padding-right: 0;
		}

		& .loadOrderButtons {
			height: 23px;
			margin: 0 0 0 5px;

			&:nth-of-type(2) {
				background-color: white;
				color: #169bd5;
				border: 1px solid #169bd5;

				&:hover,
				&:focus {
					color: #21366e;
					border: 1px solid #21366e !important;
				}
			}
		}
	}
}
