@import '../../../../theme/variables.module';

.providerInvoiceTitle {
	.header {
		display: flex;
		justify-content: space-between;

		.providerName {
			margin-right: 10px;
			margin-bottom: 4px;
			width: 100%;

			input[type='text']::-webkit-input-placeholder {
				color: #000000;
			}

			input {
				background-color: #fff !important;
				color: #000 !important;
				font-size: $font-lg !important;
				width: 100%;
				padding: 4px !important;
			}

			input:last-child {
				display: none !important;
			}
		}
	}

	.subheader {
		input {
			font-family: 'Montserrat', Arial, Helvetica, sans-serif;
			margin: 0;
		}

		.invoiceNumber {
			width: 100px;
		}

		.disabledInput {
			width: 70px;
		}
	}

	table {
		td {
			white-space: nowrap;
			vertical-align: center;
		}
	}
}

.iconButtons {
	width: 40px;
	padding: 0 6px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	.edit,
	.save {
		font-size: $font-md;
	}

	.cancel {
		font-size: $font-lg;
	}
}
