.table.table-layout-auto {
	table-layout: auto !important;
}
.bootstrap-wrapper label.search-label,
.bootstrap-wrapper label {
	margin-bottom: 0;
}

.table {
	tbody {
		.verticalCenter {
			td {
				vertical-align: middle;
			}
		}
	}
}
