@import 'ui/theme/mixins.scss';
@import 'ui/theme/_variables.module.scss';

$borderColor: darken(white, 15%);
$alternateBackgroundColor: darken(white, 4.5%);

.tableLoader {
	margin-bottom: 2px;

	min-height: 2px;
	height: 2px;
}

.dataTable {
	display: block;
	border: solid 2px $borderColor;
	border-radius: 3px;
	// margin-left: 5px;
	padding: 0px;
	font-size: 10px;

	overflow-x: auto;

	background-color: white;

	@include transition(opacity 0.33s ease);
	opacity: 1;
	&.grayed {
		opacity: 0.5;
	}

	&.inset {
		margin: 0;
		padding: 0;
		border: 0;
	}

	table {
		border-collapse: collapse;
		padding: 5px;
		border: none;
		width: 100%;

		.firstInHeaderGroup {
			border-left: 2px solid $borderColor;
		}

		thead {
			tr {
				th {
					@include noTextSelect;
					// color: #555;
					font-size: 10px;
					font-weight: bold;
					border-bottom: solid 1px $borderColor;
					padding: 2px;
				}
			}
		}
		tbody {
			tr {
				&:nth-child(odd) {
					background-color: $alternateBackgroundColor;
				}

				&.edit {
					background-color: $alternateBackgroundColor;
				}

				&.highlightedRow {
					background-color: #79c300;
					@include transition(background-color 0.1s ease);
					&:hover {
						background-color: #bce17f;
					}
				}

				&.fadedRow {
					color: #999999;
				}

				td {
					// See: https://stackoverflow.com/questions/4757844/css-table-column-autowidth
					// Auto column width
					width: 1px;
					white-space: nowrap;
					// End See
					padding-left: 3px;
					padding-right: 3px;
					&.noData {
						text-align: center;
					}
				}
			}
		}
	}
}

.cellRight {
	text-align: right;
}

.keyValueTable,
.keyValueTableBorderless {
	display: inline-block;
	border: solid 2px $borderColor;
	border-radius: 3px;
	margin: 10px;
	padding: 0px;

	overflow-x: auto;
	width: auto;

	background-color: white;

	table {
		border-collapse: collapse;
		padding: 5px;
		border: none;

		tbody {
			tr {
				// &:nth-child(odd) {
				// 	td {
				// 		// background-color: darken(white, 3.5%);
				// 	}
				// }

				&:first-child {
					th,
					td {
						border-top: none;
					}
				}

				th {
					font-family: 'Roboto', sans-serif;
					text-align: left;
					white-space: nowrap;
					font-size: $font-large;
					border: solid 1px #ccc;
					border-bottom: none;
					padding: 2px;
					padding-right: 5px;
					&:first-child {
						border-left: none;
					}
					&:last-child {
						border-right: none;
					}
				}

				td {
					font-family: 'Roboto', sans-serif;

					&:first-of-type {
						padding-left: 5px;
					}
					border: solid 1px #ccc;
					border-bottom: none;
					padding-left: 3px;
					padding-right: 3px;
					&:first-child {
						border-left: none;
					}
					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}
}

.right {
	text-align: right;
}
.center {
	text-align: center;
}
.monospace {
	font-family: 'Roboto Mono', monospace;
	line-height: 2em;
}

.keyValueTableBorderless {
	border: solid 0px $borderColor;
	padding: 0px;

	table {
		tbody {
			tr {
				td,
				th {
					border: none;
				}
			}
		}
	}
}

.tableHighlight {
	table {
		tbody {
			tr {
				@include transition(background-color 0.1s ease);
				&:hover {
					background-color: darken(white, 10%);
				}

				&.edit {
					background-color: darken(white, 2.5%);
				}
			}
		}
	}
}

.headerLabel {
	display: flex;
	// align-items: center;

	&.right {
		justify-content: flex-end;
	}
	&.center {
		justify-content: center;
	}
}

.headerGroupLabel {
	text-align: center;
}

.sortIcons {
	display: flex;
	align-items: center;
	padding-right: 2px;
}
.sortIcon {
	&.grayedSortIcon {
		color: #797979;
	}
}

.customToolbarContainer {
	border-left: solid 1px gray;
	padding-left: 10px;
}

.searchIcon,
.editIcon {
	font-size: 12px;
}

.toolbar {
	flex-wrap: nowrap;
	display: inline-flex;
}

.toolbarElement {
	margin-left: 10px;
}

.datePicker {
	width: 110px;

	.datePickerInput1 {
		&.datePickerInput2 {
			width: auto;
			height: auto;
			min-width: 100px;
			min-height: 23px;
			font: 400 13.3333px Arial;
			border: 1px solid #aaa;
			margin-top: 1px;
		}
	}
}

.filterPopup {
	max-height: 600px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.filterPopupBreaker {
	right: 62px;
}
