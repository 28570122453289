.separator {
	border-top: none;
	border-left: none;
	border-right: none;

	// border-radius: 3px;
	margin-top: 9px;
	margin-bottom: 10px;
	height: 1px;
	min-height: 1px;

	&.inset {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}
