@import 'ui/theme/_variables.module.scss';

.card {
	height: 100%;
}

.cardTitle {
	font-size: $font-large;
	line-height: $font-large;
	padding: 10px;
}

.cardType {
	font-size: $font-large;
	position: absolute;
	bottom: 0;
}

.cardBody {
	padding: 5px;
	line-height: 1.2;
	font-size: $font-small;
}
