@import 'ui/theme/_variables.module.scss';
@import 'ui/theme/mixins.scss';

$margin: 2px;
$selectedColor: #f3565d;
$backgroundColor: #f6f8ff;
$backgroundColorHovered: #f1f3fa;

.tabs {
	display: flex;
	margin-top: 5px;
	margin-left: -$margin;
	margin-right: -$margin;
}

.tabItem {
	margin-left: $margin;
	margin-right: $margin;
	padding: 10px 5px;

	font-size: $font-large;

	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px solid white;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	margin-top: 2px;

	background-color: $backgroundColor;
	color: #1e8eff;

	margin-bottom: -1px;

	cursor: pointer;

	@include transition(background-color 0.15s ease);

	&:hover {
		background-color: $backgroundColorHovered;
		border-bottom: 1px solid darken($backgroundColorHovered, 5%);
	}

	&.selected {
		font-weight: bold;
		cursor: default;
		margin-top: 0px;
		border-top: 3px solid $selectedColor;
		background-color: white;
		color: black;
	}
}

.tabContent {
	display: block;
	// padding: 5px;
	background-color: white;
	// border: solid 1px #ccc;
}
