@import '../../../theme/variables.module';

.fedexIpdManifestShipmentsTableModal {
	width: 1600px;
	padding: 0 50px;
}

.fedexIpdManifestShipmentsTable {
	display: flex;
	align-items: center;
	justify-content: center;

	table {
		width: 100%;
		font-size: $font-large;
		border-radius: 10px;
		border: solid 1px lightgrey;

		thead {
			tr {
				height: 2.5em;

				th {
					padding: 0 10px;
					border: solid 1px lightgrey;
					text-align: left;
				}
			}
		}

		tbody {
			tr {
				height: 2.5em;
				vertical-align: middle;

				td {
					vertical-align: middle;
					padding: 5px 10px;
					border: solid 1px lightgrey;
					text-align: left;
				}

				td:nth-child(1) > div {
					display: flex;
				}

				td:last-child {
					text-align: right;
				}

				// td:nth-child(1) {
				// 	min-width: 250px;
				// }

				// td:nth-child(2) {
				// 	min-width: 100px;
				// }

				// td:nth-child(3) {
				// 	text-align: right;
				// }

				// td:nth-child(4) {
				// 	line-height: 20px;
				// 	min-width: 400px;
				// 	max-width: 400px;
				// 	padding: 5px 10px;
				// }

				// td:last-child > div {
				// 	display: flex;
				// 	justify-content: space-evenly;
				// }
			}
		}
	}
}

.fedexIpdManifestShipmentsViewShipment {
	padding-right: 15px;
}
