@import 'ui/theme/mixins.scss';

.container {
	display: flex;
	cursor: text;
	border: 1px solid #797979;
	border-radius: 3px;

	@include focusHighlightNormal();

	&.focused {
		@include focusHighlightFocused();
	}
	background-color: white;
}
.icon {
	margin: auto;
	padding-left: 2px;

	@include transition(color 0.5s ease);
	color: black;

	&.focused {
		color: #999;
	}
}
.inputArea {
	flex-grow: 1;
	margin: auto;
	padding: 3px;
}
