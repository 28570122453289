$margin: 2.5px;

.threeColumn {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$margin;
	margin-right: -$margin;
	margin-top: -$margin;
	margin-bottom: -$margin;
}
.threeColumnItem {
	min-width: 30%;
	flex-grow: 1;
	flex-basis: 1;
	margin-left: $margin;
	margin-right: $margin;
	margin-top: $margin;
	margin-bottom: $margin;
}

@media only screen and (max-width: 1200px) {
	// .threeColumn {
	//   //flex-direction: row;
	//   background-color: aquamarine;
	// }
	.threeColumnItem {
		min-width: 40%;
		max-width: 49.5%;
	}
}

@media only screen and (max-width: 750px) {
	.threeColumn {
		flex-direction: column;
	}
	.threeColumnItem {
		width: 100%;
		max-width: 100%;
	}
}
