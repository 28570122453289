@import 'ui/theme/_variables.module.scss';

$borderRadius: 3px;
$fifteenMinutesOfTwentyFourHours: 1.045%;
$boxHeight: 18px;

.container {
	padding-top: 10px;
}

.schedulePageContainer {
	display: flex;
}

.scheduleTabMainContent {
	display: inline-block;
	width: 100%;

	&.mainContentSharing {
		width: 80%;
	}
}

.scheduleTabSearchInput {
	height: 29px;
	border-color: #ced4da;
	& input {
		margin-top: 4px;
		font-size: $font-large;
		font-family: inherit;
	}
}

.scheduleTabDateSpacer {
	margin-left: 5px;
}

.scheduleTabAddAppointmentButton {
	font-size: $font-large;
	margin: 0px;
}

.scheduleTabTimeSlotDockDetails {
	position: relative;
	display: inline-block;
	width: calc(20% - 5px);
	top: 0px;
	padding: 10px;
	box-sizing: border-box;
	border-left: 1px solid #cccccc;
	vertical-align: top;
	margin-left: 2px;

	& .closeButton {
		position: absolute;
		right: 0px;
		top: -20px;
		display: inline-block;
		padding: 10px;
		font-size: 2em;
	}

	& .timeSlotDockDetailsTitle {
		font-weight: bold;
		font-size: 1.5em;
		border-bottom: 1px solid black;
		text-align: center;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}

	& .timeSlotDockDetailsTime {
		text-align: center;
		font-weight: bold;
		font-size: 1em;
	}

	& .timeSlotDockDetailsBody {
		padding: 5px;
	}

	& .timeSlotDockDetailsDockCount {
		margin-top: 10px;
	}
}

.timeSlotsTable {
	width: 100%;
}

.timeSlotHourHeaderRow {
	display: flex;
	width: 100%;
}

.timeSlotHourHeader {
	border-radius: 3px;
	height: 30px;
	font-size: $font-small;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 1px;
	align-items: center;
	justify-content: center;
}

.timeSlotRow {
	height: 100px;
	display: flex;
	margin-top: 3px;
}

.slotWrapper {
	box-sizing: border-box;
	padding: 1px;
	width: 1px; // flexbox takes care of actual width
}

.voidTimeSlot {
	border: 0px;
}

.timeSlotText {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	text-align: right;
	padding: 2px;
}

.filledTimeSlot {
	display: flex;
	align-items: flex-end;
	justify-items: flex-end;
	padding: 2px;
	font-size: $font-small;
	border-radius: 3px;
	height: 100%;

	&.selectedTimeSlot {
		background-color: #bce17f;
		color: black;
	}
}

.availableTimeSlot {
	display: flex;
	align-items: flex-end;
	justify-items: flex-end;
	font-size: $font-small;
	border-radius: 3px;
	height: 100%;

	&.selectedTimeSlot {
		background-color: #bce17f;
	}
}

.timeSlotModal {
	min-width: 1000px;
}

.center {
	width: fit-content;
	margin: auto;
}

.calendarSearchBar {
	width: 500px;
}

.calendarRefreshButton {
	padding: 5px;
	background-color: #eeeeee;
	border-radius: 2px;
}

.calendarCardRow {
	min-height: 150px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 3px;
}

.calendarDateInput {
	height: 31px;
	font-size: $font-large;
}

.calendarCard {
	min-height: 150px;
	border: 1px solid black;
	border-radius: 3px;
	font-size: $font-medium;
	overflow-wrap: break-word;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.calendarCardHeader {
	padding: 5px;
	flex-grow: 0;

	& .calendarCardIdentifier {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.calendarCardBody {
	padding: 5px;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	flex-grow: 1;

	&.highlightedCard {
		background-color: #aaaaaa;
	}
}

.calendarCardEquipmentType {
	font-weight: bold;
}

.calendarCardDockName {
	font-weight: bold;
	text-align: right;
	max-width: 100%;
	flex: 1;
	align-self: flex-end;
	display: flex;
	word-break: break-word;
	align-items: flex-end;
}

.dayScrollerItem {
	height: 55px;
	padding: 10px 0px;

	&.dayScrollerItemSelected {
		background-color: #98dff1;
	}
}

.dayScrollerDay {
	text-align: center;
	width: 80px;
	font-size: $font-medium;
	cursor: pointer;

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.dayScrollerTodaySection {
	height: 1em;
}

.verticalCenter {
	display: flex;
	align-items: center;
}

.row {
	display: flex;
}

.box {
	border-width: 1px;
	border-style: none;
	padding: 5px 0;
	min-height: 19px;
	font-size: $font-small;
}

.emptyBox {
	height: $boxHeight + 2px;
}

.closedBox {
	border-style: solid;
	text-align: center;
	width: 35px;
	border-right-width: 0;
	line-height: $boxHeight;

	&:last-child {
		border-right-width: 1px;
	}
}

.fifteenMinuteSlotBox {
	border-style: solid none;
	width: $fifteenMinutesOfTwentyFourHours;
	border-top: none;

	&:last-child {
		border-style: none solid solid none;
		border-top-right-radius: 0 !important;
	}
}

.dayBox {
	width: 36px;
	padding: 5px;
	height: 15px;
	border-top-width: 0;
	border-right-width: 1px;

	&:first-child {
		border-top-left-radius: $borderRadius;
		border-top-width: 1px;
	}

	&:last-child {
		border-bottom-left-radius: $borderRadius;
	}
}

.rowWithRoundedBottomCorners {
	&:last-child {
		border-bottom-right-radius: $borderRadius;
	}
}

.rowWithRoundedTopCorners {
	&:first-child {
		border-top-left-radius: $borderRadius;
		margin-left: -1px;
	}

	&:last-child {
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: 0;
	}
}

.dockListLabel {
	margin-bottom: 2px;
}

.addDockIcon {
	text-align: right;
}

.locationSelector {
	width: 300px;
}

.dockConfigPageWrapper {
	min-height: 500px;
}

.dockConfigLabel {
	font-size: $font-large;
}

.dockConfigAddTimeSlotButton {
	margin-right: 0px;
}

.appointmentWithoutShipmentModal {
	padding: 30px;
}

.appointmentWithoutShipmentModalSave {
	margin-right: -10px;
}

.notArrivedAppointment {
	background-color: #98dff1;
}

.arrivedAppointment {
	background-color: #8693a3;
}

.loadingAppointment {
	background-color: #7f86c1;
}

.unloadingAppointment {
	background-color: #7f86c1;
}

.loadedAppointment {
	background-color: #7f86c1;
}

.unloadedAppointment {
	background-color: #7f86c1;
}

.departedAppointment {
	background-color: #aedb66;
}

.rowPadding {
	height: 22px;
}

.dockConfigBoardWrapper {
	display: inline-block;
}

.copyScheduleDayList button {
	@extend .center;

	width: 100%;
	margin-bottom: 2px;
}

.dockConfigDockList {
	& + div {
		border-left: solid 1px #7c878e;
	}
}
