.pictureUploadCard {
	height: 100%;
}

.pictureImage {
	width: 100%;
	max-width: 400px;
}

.alignOnEnd {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.verticalAlignCenter {
	display: flex;
	align-items: center;
}

.pictureCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
