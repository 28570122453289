@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin flexRow($margin) {
	display: flex;
	flex-wrap: wrap;
	margin-top: -$margin;
	margin-bottom: -$margin;
	margin-left: -$margin;
	margin-right: -$margin;
}

@mixin flexItem($margin) {
	margin-left: $margin;
	margin-right: $margin;
	margin-top: $margin;
	margin-bottom: $margin;
}

@mixin noTextSelect {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin button($color, $textColor) {
	cursor: pointer;

	@include transition(color 0.2s ease);
	@include transition(background-color 0.2s ease);
	background-color: $color;

	color: $textColor;

	&:hover {
		background-color: darken($color, 10%);
		color: darken($textColor, 20%);
		&[disabled] {
			background-color: $color;
			color: $textColor;
		}
	}
	&:active {
		background-color: darken($color, 20%);
		color: darken($textColor, 30%);
	}
}

@mixin buttonHollow($borderColor, $color, $textColor) {
	cursor: pointer;

	@include transition(color 0.2s ease);
	@include transition(background-color 0.2s ease);
	border: solid 1px $borderColor;

	color: $textColor;

	&:hover {
		background-color: darken($color, 10%);
		color: darken($textColor, 20%);
	}
	&:active {
		background-color: darken($color, 20%);
		color: darken($textColor, 30%);
	}
}

@mixin focusHighlight() {
	@include focusHighlightNormal();

	&:focus {
		@include focusHighlightFocused();
	}
}

@mixin focusHighlightNormal() {
	@include transition(box-shadow 0.25s ease);
	outline: none;
}
@mixin focusHighlightFocused() {
	box-shadow: 0 0 3px #1955a6;
}
