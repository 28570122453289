.flexRow {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: flex-start;

	&.fullWidth {
		display: flex;
		width: 100%;
	}
	&.noWrap {
		flex-wrap: nowrap;
	}
}

.childMargins {
	margin: -5px;

	> div {
		margin: 5px;
	}
}

.spaceBetween {
	justify-content: space-between;
	display: flex;
}
.spaceAround {
	justify-content: space-around;
	display: flex;
}

.center {
	justify-content: center;
	display: flex;
}

.right {
	justify-content: flex-end;
	display: flex;
}

.verticalAlignCenter {
	align-items: center;
}

.verticalAlignBottom {
	align-items: flex-end;
}

.verticalSeparator {
	align-self: stretch;
	border-top: none;
	border-bottom: none;
	border-right: none;
	margin-left: 5px;
	margin-right: 5px;
}

.horizontalSeparator {
	align-self: stretch;
	border-top: none;
	border-right: none;
	border-left: none;
	margin-top: 5px;
	margin-bottom: 5px;
}
