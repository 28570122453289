$breakpoint: 1250px;

.shipmentStopCard {
	display: flex;

	@media (max-width: $breakpoint) {
		flex-direction: column;
	}

	min-width: 200px;
	min-height: 132px;
}

.addressContainer {
	min-width: 420px;
	max-width: 420px;
}

.timeContainer {
	min-width: 440px;
	max-width: 440px;
}

.verticalSeparator {
	@media (max-width: $breakpoint) {
		display: none;
	}
}

.horizontalSeparator {
	@media (min-width: $breakpoint) {
		display: none;
	}
}

.pickupWarning {
	max-width: 300px;
	display: flex;
	margin-bottom: 10px;
}
.pickupWarningIcon {
	float: left;
	font-size: 2.9em;
	color: orange;
	margin-right: 5px;
}
.labelPickupAndDeliveryTime {
	padding-top: 3px;
	margin-left: 5px;
	margin-top: -3px;
	margin-bottom: 13px;
}

.specialInstructions {
	max-width: 300px;
}

.pickupAndDelivery {
	min-height: 115px;
}

.pickupAndDeliveryTop {
	min-height: 24px;
}

.pickupAndDeliveryPadding {
	padding-top: 2px;
}

.calendarTodayButton {
	height: 23px;
}
