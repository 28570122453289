.notificationsWrapper {
	input:nth-child(2) {
		width: 300px;
	}

	textarea {
		width: 400px !important;
		resize: none !important;
	}
}

.notificationEmailsWrapper {
	padding: 15px 0 0 20px;
}
