@import 'ui/theme/_variables.module.scss';

.formStackedContainer {
	display: flex;

	& .fullWidth {
		width: 100%;
	}
}
.item {
	margin-bottom: 5px;
}
.formRow {
	// display: table-row;
	display: flex;
	// padding: 5px;
}

.formRow2 {
	display: flex;
	margin-top: 2.5px;
	margin-bottom: 2.5px;
}

.label {
	// text-align: right;
	padding-right: 5px;
	color: #555;
	display: flex;
	align-items: center;
	font-size: 14px;
}

.descriptionRow {
	display: table-row;
}

.description {
	color: #555;
}

.errorRow {
	display: table-row;
}
.error {
	color: red;
}

.table {
	display: table;
}
.tbody {
	display: table-row-group;
}
.tr {
	display: table-row;
}
.td {
	display: table-cell;
	padding: 2px;
	&.required {
		padding-left: 5px;
	}
}
.keepSpaceForRequired {
	min-height: 20px;
}

.readOnly {
	padding: 6px 0px;
}
