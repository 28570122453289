.header {
	display: flex;
	justify-content: center;
}

.modalTitle {
	font-weight: bold;
	font-size: 1.5em;
	padding: 0.5em 2em;
	border-bottom: 1px solid;
}

.closeX {
	font-size: 1.5em;
	position: absolute;
	top: 5px;
	right: 10px;
}

.modal {
	background-color: white;
	border-radius: 6px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding-box;
	background-clip: padding-box;

	position: absolute;
	// top: 40px;
	// left: 40px;
	// right: 40px;
	// bottom: 40px;

	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	max-width: 90%;
	max-height: 100%;
	overflow: visible;
	min-width: 1px;
	min-height: 1px;
}

.content {
	padding: 10px;
}

.fullScreen {
	height: 80vh;
	width: 80vw;
	// max-height: 80vh;
	// max-width: 80vw;
}
