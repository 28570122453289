// @import 'ui/theme/_variables.module.scss';
@import '../../../theme/variables.module';
@import '../../../theme/mixins.scss';

//TODO incorporate theming
.suggestion {
	font-size: $font-sm;
	font-weight: 300;
	padding: 4px;
	cursor: pointer;
}

.selectedSuggestion {
	background-color: mix(white, #1955a6, 80%);
}

.suggestionList {
	min-width: 50px;
	max-height: 400px;
	overflow: auto;
	text-align: left;
}

.container {
	display: flex;
	margin: 10px;
	margin: 0;
	padding: 0;
	background: rgba(255, 255, 255, 0.5);
	border: 1px solid #797979;
	border-radius: 3px;

	@include focusHighlightNormal();

	&.focused {
		@include focusHighlightFocused();
	}
}

.icon {
	font-size: 15px;
	min-width: 15px;
	cursor: pointer;
	margin: auto 3px;
}
