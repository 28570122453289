@import '../../theme/variables.module';

.shipmentCardNewQuote {
	max-width: 1200px;
}

.shipmentStopCardAccessorialsNewQuote {
	font-size: $font-large;
	min-width: 266px;
	max-width: 266px;
}

.flexWrapNewQuote {
	display: flex;
	flex-wrap: wrap;
}

.billToNewQuoteModule {
	flex: 1;
	margin: 0;
}

.billToNewQuoteModuleCard {
	overflow: visible;
}

.detailsNewQuoteModule {
	flex: 2;

	.specialInstructionsSection {
		width: 435px;
	}
}

.brokerSellRateModule {
	width: fit-content;
}

.uploadDocumentsModule {
	min-width: 700px;
	max-width: 780px;
}

.clientSelector {
	max-width: 300px;
	margin-bottom: 5px;
}

.getQuotesButton {
	margin-top: 10px;
}

.icon {
	padding: 30px 0 10px;
	font-size: 64px;
}
