@import 'ui/theme/_variables.module.scss';

.brokerSellSectionCard {
	padding: 10px;
	border-radius: 10px;
	height: 100%;
	font-size: $font-large;

	& .label {
		font-weight: bold;
		margin-bottom: 6px;
	}

	& .innerCardDetails {
		padding: 10px;
		font-size: $font-medium;
		margin-top: 5px;
		margin-right: 5px;
		height: 75px;

		& div.noPadding {
			padding: 0px;
		}
	}

	.linkButtonPadding {
		padding-left: 3px;
		padding-right: 3px;
	}
}

.brokerSellSectionInteractionButtons {
	button:first-child {
		margin-left: 0px;
	}
}
