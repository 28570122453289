@import 'ui/theme/mixins.scss';

.paperCard {
	background-color: #fff;
	border: 1px solid lightgrey;
	border-radius: 3px;
	margin: 5px;
	padding: 5px;

	&.inset {
		padding: 0;
	}

	&.displayShadow {
		box-shadow: 3px 3px 2px darken(lightgrey, 10%);
	}

	&.paperCardSelected {
		@include focusHighlightFocused();
	}
}

.highlightOnHover {
	&:hover {
		background-color: lighten(#1955a6, 60%);
	}
}
