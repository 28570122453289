@import 'ui/theme/_variables.module.scss';

.inner {
	font-size: $font-medium;
}

.label {
	font-size: $font-large;
	font-weight: 600;
	margin-bottom: 5px;
}
