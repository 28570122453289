@import 'ui/theme/_variables.module.scss';

.costContainer {
	white-space: nowrap;
	padding-right: 10px;
}
.dollars {
	display: inline-block;
	font-size: 2em;
	vertical-align: middle;
}

.tableRates {
	td.cost {
		text-align: right;
	}
	.left {
		text-align: left;
	}
	.right {
		text-align: right;
	}
	.center {
		text-align: center;
	}

	font-size: $font-medium;
	width: 100%;
	border-radius: 3px;
	border: solid 1px #e3e3e3;

	thead {
		tr {
			border-radius: 3px;
			th {
				font-size: 1.25em;
				padding-left: 7px;
				padding-right: 7px;
			}
			background-color: #e3e3e3;
			height: 3.5em;
		}
	}
	tbody {
		tr {
			vertical-align: middle;
			height: 3.5em;
			td {
				vertical-align: middle;
				padding-left: 7px;
				padding-right: 7px;
			}
			&:nth-of-type(even) {
				background-color: #e3e3e3;
			}
		}
	}
}

.carrierContainer {
	display: flex;
	align-items: center;
}

.noRates {
	vertical-align: middle;
	text-align: center;
	height: 1.5em;
	font-size: $font-small;
	border: solid 1px #e3e3e3;
}

.pointerHeader {
	cursor: pointer;
}

.buttonSmall {
	font-size: 0.95em !important;
	min-width: 270px;
	margin-left: 0 !important;
}
