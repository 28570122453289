@import 'ui/theme/_variables.module.scss';

.addProviderModal {
	width: 430px;
}

.addProviderModalBody {
	padding: 0 65px;
}

.addProviderModalFooter {
	padding: 0 15px;

	& .okButton {
		width: 100px;
		text-align: center;
	}
}

.smallFont {
	font-size: $font-small;
}

.modalSearchResults {
	min-height: 300px;
}

.warningText {
	margin: auto 0px;
}

.cardTitle {
	font-size: $font-huge;
	line-height: $font-huge;
	padding: 5px;
	text-align: center;
}

.cardBody {
	padding: 15px;
	font-size: $font-large;
}
