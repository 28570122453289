.logo {
	max-width: 256px;
	padding: 2px;
	display: block;
	background-color: white;
}

.container {
	display: flex;
}
.leftArea {
	width: 260px;
	height: 100vh;
	background-color: rgb(250, 250, 250);
}
.rightArea {
	padding: 5px;
	padding-left: 40px;
	padding-top: 40px;
}

.h1 {
	color: dodgerblue;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
	font-size: 26px;
	line-height: 1.6em;
	margin-top: 0px;
	margin-bottom: 0.5em;
}
.h2 {
	font-size: 20px;
}
.h3 {
	font-family: Montserrat, sans-serif;
	font-weight: 400;
	font-size: 20px;
}
.h4 {
	font-size: 18px;
	margin-left: 20px;
}

.menuItem {
	cursor: pointer;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	font-family: Montserrat, sans-serif;
	font-size: 13px;
	text-transform: none;
	margin: 0px;
	padding: 12.5px 20px;
	&.selected {
		color: rgb(50, 50, 159);
		background-color: rgb(237, 237, 237);
	}

	&:hover {
		color: rgb(50, 50, 159);
		background-color: rgb(237, 237, 237);
	}
}

.subMenuItem {
	cursor: pointer;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	font-family: Montserrat, sans-serif;
	color: rgb(51, 51, 51);
	margin: 0px;
	padding: 12.5px 20px 12.5px 40px;
	font-size: 13px;

	&.selected {
		background-color: rgb(225, 225, 225);
	}
	&:hover {
		background-color: rgb(225, 225, 225);
	}
}

.jsonBlob {
	padding: 20px;
	background-color: #10171b;
	color: white;
	font-size: 13px;
	font-family: Courier, monospace;
	max-height: 400px;
	overflow-y: auto;
	border-radius: 3px;
}

.jsonKey {
	color: #4ed7eb;
}
.jsonValue {
	color: #60ff60;
}
.jsonValueBoolean {
	color: yellow;
}
.jsonValueNumber {
	color: rgb(74, 139, 179);
}
