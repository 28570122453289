.header {
	background-color: #4b77be;
	color: white;
	margin: 0px;
	padding: 10px;
	cursor: pointer;
}

.isOpen {
	display: inline-block;
	text-align: center;
	width: 20px;
}

.table {
	width: 100%;
	background-color: white;
	border-radius: 3px;

	thead {
		tr {
			th {
				border-radius: 3px;
				background-color: #c9c9c9;
				text-align: left;
				padding: 10px;
			}
		}
	}
	tbody {
		tr {
			td {
				padding: 10px;
				vertical-align: top;
			}
		}
	}
}

.carrierColumn {
	width: 75px;
}
