.fullCalendar {
	@import '~@fullcalendar/core/main';
	@import '~@fullcalendar/daygrid/main';

	.fc-day,
	.fc-day-top,
	.fc-event-container {
		&:hover {
			cursor: pointer;
		}
	}
}
