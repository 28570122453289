$margin: 2.5px;

.customColumn {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	overflow: auto;

	&.test {
		background-color: green;
	}
}
.customColumnItem {
	flex-grow: 1;
	flex-basis: 1;

	&.test {
		border: solid 1px blue;
		background-color: white;
		box-sizing: border-box;
	}
}

// @media only screen and (max-width: 1200px) {
//   // .threeColumn {
//   //   //flex-direction: row;
//   //   background-color: aquamarine;
//   // }
//   .threeColumnItem {
//     min-width: 40%;
//     max-width: 49.5%;
//   }
// }

// @media only screen and (max-width: 750px) {
//   .threeColumn {
//     flex-direction: column;
//   }
//   .threeColumnItem {
//     width: 100%;
//     max-width: 100%;
//   }
// }
