.customerAddFormContainer {
	min-width: 275px;
	max-width: 400px;
}
.cancelLink {
	margin-left: 35px;
	margin-top: 20px;
}
.paymentNumberForm {
	margin-top: -5px;
}
.submitButton {
	margin-right: 30px;
	margin-top: 10px;
}
.addButton {
	margin-left: 5px;
}
.customerSearch {
	width: 300px;
}
.customersDataTable {
	max-width: 600px;
}
.searchAndAddButtonContainer {
	display: flex;
	margin-left: 5px;
}
