.image {
	display: block;
	padding: 10px;
}

.pdf {
	height: 500px;
	width: 700px;
	display: block;
	padding: 10px;
}
