@import 'ui/theme/mixins.scss';

$sidebarColor1: #041e42;
$sidebarColor2: #79c300;

@mixin sidebarLink {
	color: white;
	text-decoration: none;
}

.sidebar {
	@include noTextSelect();
	float: left;
	max-width: 180px;
	margin: 2px;
	// margin-right: 15px;
	overflow-wrap: word;
	color: white;
}

.sidebarItem {
	background-color: $sidebarColor1;
	width: 150px;
	padding: 8px 12px 8px 8px;
	overflow: hidden;
	border-bottom: 1px solid white;

	a {
		@include sidebarLink;
	}

	&:first-child {
		border-radius: 3px 3px 0px 0px;
	}

	&:last-child {
		border-radius: 0px 0px 3px 3px;
		border-bottom: none;
	}

	&:hover {
		background-color: lighten($sidebarColor1, 10%);
	}
}

.sidebarSubItem {
	width: 150px;
	padding: 8px 4px 8px 16px;
	margin-left: -8px;
	background-color: darken($sidebarColor1, 5%);
	border-left: solid 5px darken($sidebarColor2, 5%);
	a {
		@include sidebarLink();
	}

	&:first-child {
		margin-top: 8px;
	}

	&:last-child {
		margin-bottom: -8px;
	}

	&:hover {
		background-color: lighten($sidebarColor1, 5%);
	}
}

.selectedItem,
.selectedItem:hover {
	background-color: $sidebarColor2;
}

.selectedSubItem,
.selectedSubItem:hover {
	background-color: darken($sidebarColor2, 10%);
}
