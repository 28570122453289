.twoColumnLeftRight {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.left {
	text-align: left;
}
.right {
	text-align: right;
}

.verticalAlignMiddle {
	align-items: center;
}
