@import '../../../../theme/_variables.module.scss';

.cardTitle {
	display: flex;
	align-items: center;
	justify-content: space-around;

	.flexEnd {
		display: flex;
		justify-content: flex-end;

		button {
			margin: 0 !important;
		}
	}
}
