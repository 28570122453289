$enable-grid-classes: true;

.bootstrap-grid-wrapper {
	@import '~bootstrap/scss/functions';
	@import '~bootstrap/scss/variables';
	@import '~bootstrap/scss/mixins/breakpoints';
	@import '~bootstrap/scss/mixins/grid-framework';
	@import '~bootstrap/scss/mixins/grid';
	@import '~bootstrap/scss/grid';
}
