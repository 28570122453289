@import '../../../../theme/variables.module';

.notifyCustomerModal {
	width: 350px;
	padding: 0 50px 30px 50px;

	.warningIcon {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 24px;
		margin: 30px 0;

		.icon {
			font-size: 60px;
			margin-bottom: 20px;
		}
	}

	.notifyMessage {
		font-size: $font-huge;
		text-align: center;
	}
}
