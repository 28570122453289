@import 'ui/theme/_variables.module.scss';

.addressContainer {
	display: flex;
}

.address {
	margin-left: 5px;
	font-size: $font-medium;
}

.addressMarkerIcon {
	font-size: 18px;
}
