@import 'ui/theme/_variables.module.scss';

.paperCardTitle {
	font-size: $font-large;
	padding: 3px 3px;
	padding-bottom: 6px;
	// background-color: lightgray;
	color: gray;
	// border-radius: 3px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	height: 25px;

	&.bordered {
		border-bottom: solid 1px lightgray;
		margin-bottom: 6px;
	}
}
