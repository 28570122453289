.subwayContainer {
	display: flex;
	align-items: center;
}

.arrow {
	font-size: 40px;
	margin-left: 3px;
	margin-right: 3px;
}
.topLabel {
	height: 20px;
	text-align: center;
}
.bottomLabel {
	height: 20px;
	text-align: center;
}

.station {
	text-align: center;
	font-size: 40px;
	min-width: 60px;
}
