@import 'ui/theme/mixins.scss';
@import 'ui/theme/_variables.module.scss';

$borderColor: #aaa;
.skvSeparator {
	border-top: none;
	border-left: none;
	border-right: none;

	// border-radius: 3px;
	margin-top: 9px;
	margin-bottom: 10px;
	height: 1px;
	min-height: 1px;
	border-color: #041e42;
}
.skvHeader {
	font-size: 1.5em;
}

.tableLoader {
	margin-bottom: 2px;

	min-height: 2px;
	height: 2px;
}

.cellRight {
	text-align: right;
}

.keyValueTable,
.keyValueTableBorderless {
	display: inline-block;
	border: solid 2px $borderColor;
	border-radius: 3px;
	margin: 10px;
	padding: 0px;

	overflow-x: auto;
	width: auto;

	table {
		border-collapse: collapse;
		padding: 5px;
		border: none;

		tbody {
			tr {
				// &:nth-child(odd) {
				// 	td {
				// 		// background-color: darken(white, 3.5%);
				// 	}
				// }

				vertical-align: top;

				&:first-child {
					th,
					td {
						border-top: none;
					}
				}

				th {
					text-align: left;
					white-space: nowrap;
					color: #555;
				}

				td {
					&:first-of-type {
						padding-left: 5px;
					}
					padding-left: 3px;
					padding-right: 3px;
				}
			}
		}
	}
}

.right {
	text-align: right;
}
.center {
	text-align: center;
}
.monospace {
	font-family: 'Roboto Mono', monospace;
	line-height: 2em;
}

.keyValueTableBorderless {
	border: solid 0px $borderColor;
	padding: 0px;

	table {
		tbody {
			tr {
				td,
				th {
					border: none;
				}
			}
		}
	}
}

.tableHighlight {
	table {
		tbody {
			tr {
				@include transition(background-color 0.1s ease);
				&:hover {
					background-color: darken(white, 10%);
				}

				&.edit {
					background-color: darken(white, 2.5%);
				}
			}
		}
	}
}

.headerLabel {
	display: flex;
	// align-items: center;

	&.right {
		justify-content: flex-end;
	}
	&.center {
		justify-content: center;
	}
}

.headerGroupLabel {
	text-align: center;
}

.sortIcons {
	display: flex;
	align-items: center;
	padding-right: 2px;
}
.sortIcon {
	&.grayedSortIcon {
		color: #ccc;
	}
}

.customToolbarContainer {
	border-left: solid 1px gray;
	padding-left: 10px;
}

.searchIcon,
.editIcon {
	font-size: 12px;
}
