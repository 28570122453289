@import 'ui/theme/mixins.scss';
@import 'ui/theme/_variables.module.scss';

$margin: 2px;
$selectedColor: #21366e;
$backgroundColor: white; //#f6f8ff;
$backgroundColorHovered: #f1f3fa;
$backgroundColorSelected: #f1f3fa;

$borderBottomColor: #999;

.tabs {
	display: block;

	// margin-top: 5px;
	// margin-bottom: 5px;
	background-color: white;

	// border-bottom: solid 1px black;
}

.tabsSpacer {
	flex: 1 0 0;
	border-bottom: solid 1px $borderBottomColor;
	margin-bottom: 1px;
}

.tabsItems {
	display: flex;
}

.tabItem {
	padding: 10px 12.5px;

	font-size: $font-large;

	// border-top: 1px solid black;
	border-bottom: 1px solid $borderBottomColor;

	background-color: $backgroundColor;
	color: #333333;

	margin-bottom: 1px;

	cursor: pointer;

	@include transition(background-color 0.15s ease);

	border-radius: 3px 3px 0 0;

	&:hover {
		background-color: $backgroundColorHovered;
		border-bottom: 3px solid $selectedColor;
		margin-bottom: 0px;
	}

	&.selected {
		font-weight: bold;
		cursor: default;
		margin-top: 0px;
		border-bottom: 3px solid $selectedColor;
		margin-bottom: 0px;
		background-color: $backgroundColorSelected;
		color: black;
	}
}

.tabContent {
	padding: 5px;
	background-color: white;
	border: solid 1px #ccc;
}
