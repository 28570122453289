.helpText {
	width: 200px;
	font-size: 12px;
	white-space: normal;
	display: flex;
}

.signupArea {
	background-color: white;
	padding: 5px;
}
.providerPortalContainer {
	width: 450px;
}

.signupArea {
	background-color: white;
	padding: 5px;
}

.redirect {
	text-align: center;
}
