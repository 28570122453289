@import './_variables.module.scss';
@import './mixins.scss';

.test {
	margin: 0px;
}

.app {
	margin: 0px;
	// color: #61dafb;
}

// see: https://css-tricks.com/almanac/properties/u/user-select/
.noTextSelect {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.clickable {
	cursor: pointer;
}
.hidden {
	visibility: hidden;
}

// Theme-able

// @font-face {
// 	font-family: 'Sofia';
// 	font-family: 'Montserrat';
// 	// src: url('/fonts/SofiaProRegular.ttf') format('truetype');
// }

.fontPrimary {
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
	// font-family: 'Sofia', Arial, Helvetica, sans-serif;
}
.fontSecondary {
	font-family: 'Montserrat', Arial, Helvetica, sans-serif;
	// font-family: 'Sofia', Arial, Helvetica, sans-serif;
}

.fontSmall {
	font-size: $font-small;
}
.fontMedium {
	font-size: $font-medium;
}
.fontLarge {
	font-size: $font-large;
}
.fontHuge {
	font-size: $font-huge;
}

input[type='text'],
textarea {
	@include focusHighlight();

	&:read-only {
		font-family: 'Montserrat', Arial, Helvetica, sans-serif;
	}
}

.width100 {
	width: 100%;
}

.boldText {
	font-weight: bold;
}
