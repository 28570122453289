@import 'ui/theme/tmsDarkTheme.module.scss';

.downloadBox {
	border: 2px solid $lightGray;
	cursor: pointer;
	height: 200px;
	width: 200px;
	margin-bottom: 15px;

	.downloadBoxContent {
		background-color: $lightGray;
		font-size: 35px;
		text-align: center;
		font-weight: bolder;
		height: 150px;
		width: 100%;
		color: $gray;

		span {
			margin-bottom: 10px;
			font-size: 22px;
			padding-top: 50px;
		}
	}

	.downloadBoxSuccess {
		height: 50px;
		width: 100%;
		line-height: 50px;
		text-align: center;
		color: white;
		font-size: 16px;
		background-color: $green;
	}
}
