.iconButton {
	width: 40px;
	height: 40px;
	border: 0;
	cursor: pointer;
	background-color: white;

	&:focus {
		background-color: #3b5998;
	}
}

.inlineIcon {
	display: inline;
}

.large {
	font-size: 20px;
}

.disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
}
