.customerManagementTabs > div {
	margin-bottom: -1px;
}

.customerManagementTabsContainer {
	border-left: 1px solid lightgrey;
	border-right: 1px solid lightgrey;
	border-bottom: 1px solid lightgrey;

	height: 600px;
	min-height: 600px;
	max-height: 600px;

	padding: 30px;
}
