.addProviderModalWarning {
	.warningIcon {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 24px;
		margin: 30px 0;

		.icon {
			font-size: 48px;
			margin-bottom: 20px;
		}
	}

	.warningMessage {
		font-size: 14px;
		margin-bottom: 100px;

		div {
			margin-bottom: 20px;
		}

		.highlight {
			color: red;
			text-decoration: underline;
		}
	}
}
