$gray: #ccc;
$blue: #ccc;
$green: #ccc;
$red: #ccc;
$orange: #ccc;
$textColor: black;

@mixin button($color, $textColor) {
	cursor: pointer;
	background-color: $color;
}

$colors: (
	gray: $gray,
	red: $red,
	green: $green,
	blue: $blue,
	orange: $orange,
);
@each $name, $color in $colors {
	.#{$name} {
		background-color: $color;
	}
	.#{$name}Button {
		@include button($color, $textColor);
	}
	.#{$name}Border {
		border: solid 1px $color;
	}
	.#{$name}BorderBottom {
		border-bottom: solid 1px $color;
	}
}
