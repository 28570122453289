@import 'ui/theme/_variables.module.scss';

.ltr {
	display: block;
}

.rtl {
	direction: rtl;
	display: block;
}

.editable {
	cursor: pointer;
}

.invalidT {
	font-weight: bold;
	color: red;
}

.testId {
	font-size: $font-medium;
	margin-left: 5px;
}
