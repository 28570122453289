@import 'ui/theme/_variables.module.scss';

.cardTitle {
	font-size: $font-huge;
	padding: 5px 10px;
	color: white;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.toggleable {
	cursor: pointer;
}
