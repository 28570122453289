@import '../../../../theme/variables.module';

.providerCharges {
	padding: 2px;

	.titleContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;

		h4 {
			margin: 0;
			padding: 0;
			span {
				font-weight: normal;
				font-size: $font-md;
			}
		}

		button {
			margin: 0;
			padding: 0;
			span {
				font-size: $font-sm;
			}
		}
	}

	table {
		width: 100%;
		border: 1px solid #808080;
		border-radius: 3px;
		border-spacing: 0;

		thead {
			background-color: #ddd;

			tr {
				padding-left: 0 6px;

				th {
					padding: 10px 6px;
					white-space: nowrap;
					font-size: $font-xs;
					font-weight: bold;
					text-align: right;

					background-color: #ddd;
				}

				th:nth-child(1) {
					text-align: center;
				}

				th:nth-child(2) {
					text-align: left;
					// min-width: 160px;
					width: 30%;
				}

				th:nth-child(3) {
					width: 3%;
				}
			}
		}

		tbody {
			background-color: #fff;

			tr {
				padding: 0 6px;

				td {
					padding: 10px 5px;

					font-size: $font-xs;
					font-weight: bold;
					text-align: center;

					input {
						font-family: 'Montserrat', Arial, Helvetica, sans-serif;
						font-size: $font-xs;
						padding: 3px 3px;

						&.alignLeft {
							text-align: left;
						}

						&.alignRight {
							text-align: right;
						}

						&.alignCenter {
							text-align: center;
						}
					}

					.iconButtons {
						width: 40px;
						padding: 0 6px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.edit,
						.save {
							font-size: $font-md;
						}

						.cancel {
							font-size: $font-lg;
						}
					}
				}
			}
		}
	}
}

.asyncTypehead {
	input,
	> div {
		background-color: #ffffff !important;
		height: 23px !important;
		font-size: $font-xs !important;
	}

	input[type='text']:disabled,
	input[type='text']:enabled {
		color: #000000;
	}

	div {
		font-size: $font-xs !important;
	}

	input:first-child {
		border-color: #797979 !important;
	}

	input:last-child {
		display: none !important;
	}
}

.asyncTypeheadChargeCode {
	input:first-child {
		text-align: center;
	}
}

.enabledInput {
	font-size: $font-xs !important;
	border: 1px solid #797979 !important;

	input {
		border: 0 !important;
	}
}

.disabledInput {
	font-size: $font-xs !important;

	input {
		border: 0 !important;
	}
}
