@import '../../../../theme/variables.module';

.bsTabPills {
	a {
		font-size: $font-large;

		background-color: #f2f2f2 !important;
		border-radius: 15px !important;
		border: 1px solid lightgray;
		color: #000000 !important;
		padding: 0.3rem 1.2rem !important;

		&:hover {
			background-color: #599000 !important;
		}
		// &:focus {
		// 	background-color: #79c300 !important;
		// }
		&.bsTabPillActive {
			background-color: #79c300 !important;
			color: #ffffff !important;
		}
	}
}

.bsTabPillActive {
	background-color: #79c300 !important;
}

.bsTabPillsNavItem {
	margin-right: 20px;
}

.bsTabContent {
	padding: 30px 20px;
}
