$minWidth: 150px;
$maxWidth: 250px;

.select {
	min-width: $minWidth;
	//max-width: $maxWidth;
	border: 1px solid gray;
	border-radius: 2px;
	overflow-y: scroll;
}

.selectItem {
	padding: 2px;
	min-width: $minWidth;
	//max-width: $maxWidth;
}

.chip {
	margin: 5px;
}
