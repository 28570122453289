.interactionOptions {
	display: flex;
	align-items: center;
}

.ratesTableBadge {
	min-width: 85px;
	& .buttonText {
		width: 100%;
	}
}
