@import '../../../../theme/variables.module';

.providerInvoiceNotes {
	margin: 0 2px;

	.providerInvoiceNotesHeader {
		display: flex;
		flex-direction: flex-end;
		font-size: $font-lg;

		.editSaveIcon {
			font-size: $font-md;
			margin-left: 12px;
		}
	}
}
