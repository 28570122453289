@import '../../../../theme/variables.module';

.pageTitle {
	margin: 10px 0 20px 0;
	display: flex;
	align-items: center;

	h1 {
		white-space: nowrap;
		margin-right: 16px;
	}

	label,
	input {
		font-family: 'Montserrat', Arial, Helvetica, sans-serif;
		font-size: $font-xl;
		font-weight: bold;
		height: $font-xl;
		margin-right: 16px;
		width: 180px;
	}

	input {
		margin-top: -1px;
		margin-left: -3px;
		margin-right: 21px;
	}

	> div {
		margin: 0;
		padding: 0;
		display: flex;
	}

	> div:nth-child(2) {
		width: 100px;
		align-items: center;
		flex: 1;

		.editSaveIcon {
			font-size: $icon-lg;
			margin-right: 12px;
		}

		.cancelIcon {
			font-size: $icon-xl;
		}
	}
}

.invoiceStatusButton {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-left: 0 !important;

	.brokerInvoiceButtons > button {
		background-color: transparent;
		color: #2ea9f0;
		text-decoration: underline;

		&:hover {
			background-color: transparent;
		}
	}
}

.invoiceCustomerConfirmModal {
	width: 350px;
	padding: 0 50px;

	.warningIcon {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 24px;
		margin: 30px 0;

		.icon {
			font-size: 48px;
			margin-bottom: 20px;
		}
	}

	.warningMessage {
		font-size: $font-large;
		text-align: center;
	}
}
