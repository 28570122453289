@import 'ui/theme/_variables.module.scss';

.calendarInput {
	height: 30px;
}

.datePicker {
	width: 110px;
}

.searchInput {
	height: 29px;
	& input {
		margin-top: 4px;
		font-size: $font-large;
		font-family: inherit;
	}
}
