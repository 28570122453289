.mapIcon {
	font-size: 14px;
	margin-top: -4px;
}

.reversedMapIcon {
	transform: scaleX(-1);
}

.markerDropShadow {
	filter: drop-shadow(1px 2px 4px #999);
}

.routeMarker {
	margin-top: -24px;
	font-size: 24px;
	margin-left: -4px;
}
