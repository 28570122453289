@import '../../../../../theme/variables.module';

.trackingEventsHeader {
	font-size: $font-larger;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	height: 100%;
	padding: 0 0 5px 1px;
}

.addEventButton {
	margin-right: 0px !important;
}

.trackingEventDescriptionColumn {
	max-width: 400px;
}

.deleteIcon {
	min-width: 30px;
}

.trackingEventTimeColumn {
	padding-right: 5px;
}

.shipNotificationEmailRow {
	align-items: center;
	padding-right: 10px;
}

.shipNotificationEmailCol {
	display: flex;
	align-items: center;

	div:first-child {
		margin-bottom: 0;
	}

	button {
		margin: 23px 5px 0 10px !important;
	}

	input {
		width: 375px !important;
	}
}
