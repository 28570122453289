@import 'ui/theme/_variables.module.scss';

.popOver {
	position: absolute;
	overflow-y: scroll;
	top: 0px;
	width: 300px;
	height: 700px;
	background-color: ghostwhite;
	z-index: 99999;
	font-size: $font-medium;

	&.right {
		right: 0px;
		border-left: 1px solid #ccc;
		box-shadow: -8px 0px 8px -8px #aaa;
	}

	&.left {
		left: 0px;
		border-right: 1px solid #ccc;
		box-shadow: 8px 0px 8px -8px #aaa;
	}
}

.shipmentDetailsContainer {
	padding: 5px;
}

.mapContainer {
	position: relative;
	border: 1px solid #ccc;
}

.filterContainer {
	display: flex;
	margin-bottom: 10px;

	> div {
		margin-left: 3px;
		margin-right: 3px;
	}

	:first-child {
		margin-left: 0px;
	}

	:last-child {
		margin-right: 0px;
	}
}

.dateRangeSeparator {
	display: flex;
	align-items: center;
}

.datePicker {
	width: 110px;
}
