.pictureUpload {
	margin-top: 30px;
}

.pictureUploadContainer {
	display: flex;
	align-items: center;
}

.pictureImage {
	width: 100%;
	max-width: 300px;
	height: 300px;
	line-height: 300px;
	text-align: center;
	margin-left: 30px;

	background-color: rgba(0, 0, 0, 0.3);
	background-position: center;
	background-size: contain;
}
