@import '../../theme/variables.module';

.filterCustomerWrapper {
	display: flex;
	align-items: center;
}

.filterCustomerInputGroup {
	align-items: center !important;

	input {
		border: 1px solid #797979 !important;
		border-radius: 3px !important;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		height: 31px !important;
		padding: 3px !important;
		transition: box-shadow 0.25s ease !important;

		&:focus {
			box-shadow: 0 0 3px #1955a6 !important;
		}
	}

	button {
		font-size: $font-large !important;
		margin: 0px !important;
		height: 31px;
		display: flex !important;
		align-items: center;

		&:focus {
			box-shadow: 0 0 3px #1955a6 !important;
		}
	}
}

.clientSelector {
	min-width: 250px;
}

.filterCustomerPencilIcon {
	padding-left: 10px;
}

.filteredCustomerDisabledInput {
	background-color: #eee !important;
}
