.addEventModalHalf {
	min-width: 300px;

	.datePickerWrapper {
		& .componentWrapper {
			width: 100%;
		}

		& input {
			// for time picker in date picker
			width: 100% !important;
		}

		& .dateTimePickerInput {
			// for input textbox of date picker component
			height: 21px;
		}
	}

	.internalCheckboxWrapper .internalCheckboxWrapper2 label {
		display: flex;
	}
}
