.layoutTms2 {
	padding: 1em;
}

.layoutTms3 {
	background-color: white;
	min-height: 500px;
}

.header {
	margin-bottom: 8px;
	padding-top: 5px;
}

.headerBar {
	display: flex;
	> div {
		margin: 5px;
	}
	min-height: 45px;
}

.sidebar {
	position: absolute;
	left: 0;
}
.mainPanel {
	// position: absolute;
	// right: 0;
	width: 100%;
	overflow: hidden;
	background-color: white;
}
