@import '../../../theme/variables.module';

.addChargeCodeButton {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	font-size: $font-md;

	padding: 10px 0;
}

.chargeCodeCatalogTable {
	th {
		background-color: #eee;
		font-size: $font-sm;
	}
	td {
		font-size: $font-sm;
	}
	td:last-child {
		font-size: $font-md;
		text-align: center;
		> div {
			padding: 0 5px;
			display: inline-block;
		}
	}
}

.addChargeCodeCatalogModal {
	width: 260px;
	padding: 20px 50px;

	button {
		font-size: $font-sm;
	}
}

.deleteChargeCodeCatalogModal {
	width: 260px;
	padding: 20px 50px;

	button {
		font-size: $font-sm;
	}
}

.noLabel {
	> div:first-child {
		display: none;
	}
}

.searchBox {
	padding: 4px;
	height: 36px !important;
	font-size: $font-sm;
}

.selectBox {
	padding: 4px;
	height: 36px !important;
	font-size: $font-sm;
	font-weight: 400;

	display: flex;
	align-items: center;

	background-color: #fff;
}
