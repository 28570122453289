@import 'ui/theme/mixins.scss';

.infiniteLoader {
	min-height: 2px;
	height: 2px;
}

.infiniteLoaderBar {
	border-radius: 2px;
	height: 2px;
	background-color: #ccc;
	opacity: 0;
	@include transition(opacity 0.05s ease);
	// @include transition(width 0.05s linear);
}
