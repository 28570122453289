@import '../../../theme/_variables.module.scss';

.referenceInformationTable {
	display: flex;
	font-size: $font-large;

	table {
		th {
			padding: 8px 10px;
			text-align: left;
		}
		td {
			text-align: right;
		}
	}
}
