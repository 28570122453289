@import 'ui/theme/_variables.module.scss';

.inputText {
	margin: 0;
	padding: 2px;
	background: rgba(255, 255, 255, 0.5);
	border: 1px solid #797979;
	border-radius: 3px;
	margin-top: 3px;
	width: 100%;
}

.expectedSubText {
	font-size: $font-small;
}
