.bookCarrierModal {
	width: 430px;
	padding: 0 50px;
}

.bookCarrierFormStackedPillSwitch {
	label {
		display: none;
	}
}
