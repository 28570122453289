@import 'ui/theme/mixins.scss';
@import 'ui/theme/_variables.module.scss';

$border: solid 1px black;
$highlightColor: #abcdef;

.calendar {
	@include noTextSelect();

	padding: 5px;
	margin: 5px;
	border: $border;
	border-radius: 3px;
	display: inline-block;

	font-size: $font-large;

	&.inset {
		border: none;
		margin: none;
	}
}

.week {
	display: flex;

	// Round top row
	&:first-child {
		.day,
		.dayOutOfMonth {
			&:first-child {
				border-top-left-radius: 5px;
			}

			&:last-child {
				border-top-right-radius: 5px;
			}
		}
	}
	// Round bottom row
	&:last-child {
		.day,
		.dayOutOfMonth {
			// Collapse borders
			border-bottom: $border;

			&:first-child {
				border-bottom-left-radius: 5px;
			}

			&:last-child {
				border-bottom-right-radius: 5px;
			}
		}
	}
}

.day,
.dayOutOfMonth {
	display: flex;
	align-items: center;
	justify-content: center;

	border: $border;
	border-bottom: none;
	padding: 2px;

	width: 20px;
	height: 20px;

	// Collapse borders
	border-left: none;
	&:first-child {
		border-left: $border;
	}

	cursor: pointer;

	@include transition(background-color 0.2s ease);
	&:hover {
		background-color: $highlightColor;
	}
}

.dayOutOfMonth {
	background-color: #ccc; //darker(white, 10%);
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
