td.cost {
	text-align: right;
}
td.right {
	text-align: right;
}

.costContainer {
	padding-right: 10px;
}
.dollar {
	display: inline-block;
	font-size: 2em;
	vertical-align: middle;
}

.tableRates {
	width: 100%;
	border-radius: 3px;
	thead {
		tr {
			th {
				border-radius: 3px;
			}
		}
	}
	tbody {
		tr {
			vertical-align: middle;
			td {
				vertical-align: middle;
			}
		}
	}
}

.carrierContainer {
	display: flex;
	align-items: center;
}
