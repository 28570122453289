.fieldset {
	margin: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 3px;
	border: solid gray 1px;

	&.inset {
		padding: 0;
		legend {
			margin-left: 10px;
		}
	}
}
