@import '../../../../theme/variables.module';

.customerInvoiceDetails {
	span {
		font-size: $font-large;
	}

	.tableWrapper {
		padding: 8px 10px;
	}

	table {
		th {
			padding: 8px 10px;
			text-align: left;
		}

		td {
			padding: 8px 0;
			font-size: $font-large;
			text-align: right;
		}
	}
}
