@import 'ui/theme/_variables.module.scss';

.card {
	font-size: $font-medium;
	background-color: white;
	border-radius: 3px;

	&.notFullWidth {
		display: inline;
	}
	overflow: hidden;
}

.fullWidth {
	width: 100%;
}

.cardContent {
	padding: 0px;
}

.cardContentPadding {
	padding: 10px;
}

.fillHeight {
	height: 100%;
}
