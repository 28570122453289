@mixin containerSpacing($margin) {
	display: flex;

	> div {
		margin-left: $margin;
		margin-right: $margin;
	}

	:first-child {
		margin-left: 0px;
	}

	:last-child {
		margin-right: 0px;
	}
}

.calendarContainer {
	min-width: 600px;
	max-width: 1200px;
}

.date {
	padding-left: 0px;
}

.filterContainer {
	@include containerSpacing(5px);
}

.interactionsContainer {
	@include containerSpacing(10px);
}

.prevNextIcon {
	align-self: center;
}
