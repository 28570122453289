@import 'ui/theme/_variables.module.scss';

body {
	margin: 0;
	padding: 0;
	line-height: 1;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 {
	margin: 0;
	padding: 0;
	font-size: $font-xl;
}

h3 {
	margin: 0;
	padding: 0;
	padding-left: 10px;
	font-size: $font-huge;
}

ul {
	margin: 0;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 0;
	padding-left: 40px;
}
