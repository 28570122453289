$margin: 2.5px;

.twoColumn {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$margin;
	margin-right: -$margin;
	margin-top: -$margin;
	margin-bottom: -$margin;
	align-items: flex-start;
}
.twoColumnItem {
	min-width: 45%;
	flex-grow: 1;
	flex-basis: 1;
	margin-left: $margin;
	margin-right: $margin;
	margin-top: $margin;
	margin-bottom: $margin;
}

@media only screen and (max-width: 750px) {
	.twoColumn {
		flex-direction: column;
	}
	.twoColumnItem {
		width: 100%;
		max-width: 100%;
	}
}
