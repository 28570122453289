.formRowContainer {
	display: flex;
}

.formRow {
	display: table-row;
	padding: 5px;
}

.labelLeft {
	text-align: right;
	color: #555;
	vertical-align: middle;
}

.labelRight {
	text-align: left;
	padding-left: 5px;
	color: #555;
}

.descriptionRow {
	display: table-row;
}

.description {
	color: #555;
}

.errorRow {
	display: table-row;
}
.error {
	color: red;
}

.table {
	display: table;
}
.tbody {
	display: table-row-group;
}
.tr {
	display: table-row;
}
.td {
	display: table-cell;
	padding: 2px;
	&.required {
		padding-left: 5px;
	}
}
