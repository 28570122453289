@import 'ui/theme/_variables.module.scss';

.tableWrapper {
	margin: 0 auto;
	width: 900px;
	padding: 20px;

	.tableSearch {
		margin-bottom: 12px;
	}

	table {
		th {
			padding: 0 20px;
		}

		td {
			text-align: center;
			line-height: 1.5;
			padding: 0 10px;
		}
	}
}
