@import 'ui/theme/_variables.module.scss';

.datePicker {
	.prepositionSelector {
		& input {
			height: 29px;
			padding-left: 5px;
			font-size: $font-large;
			font-family: inherit;
			vertical-align: middle;
		}
	}

	& input.calendarInput {
		height: 31px;
		padding-left: 5px;
		font-size: $font-large;
		font-family: inherit;
		vertical-align: middle;
	}
}

.preposition {
	padding-top: 7px;
}
