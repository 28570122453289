@import 'ui/theme/_variables.module.scss';

$color: #21366e;

.checkbox {
	margin: 2px;

	label {
		display: flex;
		align-items: center;
	}
	input {
		position: absolute;
		height: 1px;
		width: 1px;
		opacity: 0.001;
	}

	.icon {
		color: $color;
		font-size: 1.5em;
	}
	:hover {
		.icon {
			color: darken($color, 10%);
		}
	}
}

.whiteBackground {
	background-color: white;
}
