.cardSubTitle {
	font-weight: bold;
	color: #333333;
	display: block;
	margin-bottom: 10px;
}

.billToLabel {
	padding-top: 5px;
	padding-bottom: 5px;
}

.billToDropdown {
	padding-right: 15px;
}
