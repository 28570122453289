// https://gist.github.com/garyharan/957284
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow: inset $top $left $blur $color;
		-moz-box-shadow: inset $top $left $blur $color;
		box-shadow: inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

$border-radius: 3px;
$hiddenBreaker2Height: 25px;

.popupContainer {
	display: inline-flex;
}
.popupHiddenBreaker {
	position: absolute; // Breaks out of overflow: hidden
}
.popupHiddenBreaker2 {
	position: relative; // Breaks out of overflow: hidden
	top: $hiddenBreaker2Height;
}

.popup {
	white-space: nowrap;

	position: absolute;
	border: solid 1px #ccc;
	padding: 5px;
	// margin: 5px;
	background-color: white;
	@include box-shadow(2px, 2px, 2px, #ccc, false);
	z-index: 1000;
	&.visible {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.2s linear;
	}
	&.hidden {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s 0.2s, opacity 0.2s linear;
	}

	&.inset {
		padding: 0px;
	}
}

.relativeExample {
	position: relative;
	width: 200px;
	height: 100px;
	background-color: blue;
	margin: 50px;
	margin-left: 100px;
}
.example {
	background-color: red;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px;
	height: 25px;
}

.topLeft {
	position: absolute;
	bottom: $hiddenBreaker2Height;
	right: 100%;
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
}

.topRight {
	position: absolute;
	bottom: 100%;
	left: 100%;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	border-top-left-radius: $border-radius;
}

.bottomLeft {
	position: absolute;
	top: 100%;
	right: 100%;
	border-top-left-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}

.bottomRight {
	position: absolute;
	top: 100%;
	left: 100%;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
}

.topLeftConnectedToBottomLeft {
	position: absolute;
	top: 100%;
	left: 0%;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
}

.verticalEllipsis {
	font-size: 16px;
}
