@import 'ui/theme/_variables.module.scss';

.instructionsItem {
	span {
		font-size: $font-large;
	}
	a {
		font-size: $font-large;
	}
}

.instructionsCredentials {
	span {
		font-size: $font-larger;
		font-weight: 600;
	}
}
