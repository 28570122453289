.timePicker {
	cursor: pointer;
}
.timeSelector {
	min-width: 160px;
	align-content: center;
}

.timeSelectorSimpleContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 300px;
	min-width: 280px;
}

.timeSelectorSimpleItem {
	margin: 0px;
}
