$font-small: 10px;
$font-medium: 12px;
$font-large: 14px;
$font-larger: 16px;
$font-huge: 20px;

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 20px;
$font-xl: 32px;

$icon-xs: 10px;
$icon-sm: 12px;
$icon-md: 14px;
$icon-lg: 20px;
$icon-xl: 26px;

$selectedColor: #169bd5;
$backgroundColor: lightgrey;
$backgroundColorHovered: #b3b3b3;

.noop {
	/*!noop*/
}
