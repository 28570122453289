.inputText {
	margin: 0;
	padding: 2px;
	background: rgb(255, 255, 255);
	border: 1px solid #797979;
	border-radius: 3px;
	// max-width: 200px;
	// min-width: 150px;
	width: 100%;
	box-sizing: border-box;
	vertical-align: top;
	outline: none;

	&.notResizable {
		resize: none;
	}

	&.borderless {
		padding-top: 0;
		padding-bottom: 0;
		border: none;
		resize: none;
		&:focus {
			box-shadow: none;
		}
	}

	&.readOnly {
		border-style: none;
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}
.singleLine {
	height: 23px;
	&.borderless {
		height: 21px;
	}
}
