.replaced {
	color: #943230;
	border: 1px solid #e0b4b4;
	background-color: #fff6f6;
}

.newText {
	color: #205721;
	background-color: #fcfff5;
	border: 1px solid #a3c293;
}
