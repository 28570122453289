.container {
	display: flex;
	align-items: center;
	// justify-content: flex-end;
}

.cancel {
	margin-right: 5px;
	padding-bottom: 3px;
}
.ok {
	margin-left: 5px;
}
