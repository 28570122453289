@import 'ui/theme/_variables.module.scss';

.requiresPermission {
	border: solid 1px;
	border-radius: 3px;
	margin-bottom: 4px;
}

.permissionTag {
	background: #777;
	color: white;
	font-size: $font-medium;
	padding: 2px 4px;
	border-bottom-right-radius: 3px;
	width: auto;
	display: inline-block;
}

.green {
	border-color: green;
}
.red {
	border-color: red;
}
