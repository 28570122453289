@import 'ui/theme/_variables.module.scss';

.badge {
	padding: 2px;
	border-radius: 3px;
	font-size: $font-small;
	font-weight: bold;
	color: white;
	text-align: left;
	display: inline-block;
	margin: 2px;
}

.bigBadge {
	font-size: $font-large;
	text-align: center;
	display: inline-block;

	padding: 5px 8px;
	margin: 3px;
	border-radius: 10px;
}

.optional {
	font-style: italic;
	font-size: 0.8em;
	color: #555;
}
