@import '../../theme/variables.module';

.companyManagementTabs > div {
	margin-bottom: -1px;
}

.companyManagementTabsContainer {
	border-left: 1px solid lightgrey;
	border-right: 1px solid lightgrey;
	border-bottom: 1px solid lightgrey;

	margin-top: -2px;
	padding: 15px;
}
