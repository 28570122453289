@import 'ui/theme/_variables.module.scss';

$bootstrap-red: #b20023;
$bootstrap-green: #79c300;

.card {
	height: 100%;

	&.specificityClass {
		// ONLY NEEDED TO OVERRIDE THE BOOTSTRAP CLASS

		&.deliveryAndPickupCard {
			border-top: 1px solid $bootstrap-green;
			border-left: 1px solid $bootstrap-green;
			border-right: 1px solid $bootstrap-red;
			border-bottom: 1px solid $bootstrap-red;
		}
	}
}

.cardTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: $font-large;
	line-height: $font-large;
	padding: 10px 15px;
}

.cardType {
	display: flex;
	align-items: center;
	justify-content: space-around;

	font-size: $font-large;
}

.cardIconEdit {
	margin: 0 0 0 15px;
}

.cardIconSave {
	margin: 0 0 0 15px;
}

.cardIconCancel {
	margin: 0 0 0 10px;
}

.cardBody {
	padding: 5px;
	line-height: 1.2;
	font-size: $font-small;

	> div:first-child {
		width: 100%;

		margin: 0;
		padding: 10px;

		table {
			width: 100%;

			th {
				width: 50%;
			}
		}
	}
}

.cardEditableForm {
	width: 100%;
	padding: 10px 15px 0 15px;
}

.cardEditableFormInput {
	position: relative;
	width: 100%;

	font-size: $font-small;

	label {
		span {
			font-size: $font-small;
		}
	}

	input {
		width: 100%;
	}
}
