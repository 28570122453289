.fileUploadContainer {
	display: flex;
	justify-content: left;
	align-items: left;
}

.fileUploadExpanded {
	margin-top: 1px;
	width: 75%;
	height: 200px;
}

.fileUploadContracted {
	width: 35%;
	margin-bottom: 8px;
}

.noLeftMargin {
	margin-left: 0px;
}

.uploadShipmentsButton {
	margin: 5px 0px;
}
