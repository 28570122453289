@import '../../../theme/variables.module';

.titledBorderBoxesWrapper {
	display: flex;

	> div {
		margin: 5px;
	}
}

.checkboxRow {
	font-size: $font-sm;
	label {
		width: 100% !important;
		div {
			display: inline-block !important;
		}
	}
}

.titledBorderBox {
	width: 50%;
	height: 175px;
	padding-left: 24px;
}
