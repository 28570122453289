@import '../../../../theme/variables.module';

.invoiceNotesHeader {
	display: flex;
	flex-direction: flex-end;

	.editSaveIcon {
		font-size: $icon-lg;
		margin-left: 12px;
	}
}
