.unbookCarrierModal {
	width: 430px;
	padding: 0 50px;
}

.unbookCarrierFormStackedPillSwitch {
	label {
		display: none;
	}
}
