.contracts {
	margin-left: 30px;
}

.contractsContainer {
	display: block;
	overflow-y: scroll;
	max-height: 500px;
}

.right {
	display: flex;
	justify-content: flex-end;
}
