.configurable {
	border: solid 1px;
	border-radius: 3px;
	margin-bottom: 4px;
}

.configurableTag {
	background: #777;
	color: white;
	font-size: 12px;
	padding: 2px 4px;
	border-bottom-right-radius: 3px;
	width: auto;
	display: inline-block;
}

.green {
	border-color: green;
}
.red {
	border-color: red;
}
