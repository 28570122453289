@import 'ui/theme/_variables.module.scss';

.card {
	height: 100%;
}

.cardTitle {
	display: flex;
	align-items: center;

	font-size: $font-large;
	line-height: $font-large;
	padding: 10px 15px;
}

.cardBody {
	padding: 5px;
	line-height: 1.2;
	font-size: $font-small;

	> div:first-child {
		width: 100%;

		margin: 0;
		padding: 10px;

		table {
			width: 100%;

			th {
				width: 50%;
			}
		}
	}
}
